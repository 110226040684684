import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import img1 from "../../images/slider_new_1.svg"; //Time
import img3 from "../../images/slider_new_2.svg"; //Old Lady
import img2 from "../../images/slider_new_3.svg"; //Garbage Bags
import img4 from "../../images/slider_new_4.svg"; //Delivery
import 'react-alice-carousel/lib/alice-carousel.css';
import Works from './works';
 
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};

const items = [

    
      <div className={'sliderTextWrapper'}>
        <div className={'col-md-4 Active sliderText'}> 
          <h6 style={{fontSize: '32px',fontWeight: '600'}}> No more laundry day! </h6>
          <span style={{fontSize: '22px',color: '#000',fontWeight: '400' , lineHeight: '32px'}}>Sit back and relax, Let us take care of this chore.</span>  
        </div>
        <p className={'col-md-7'}>
          <img src={img1} onDragStart={handleDragStart} className="sliderImages sliderImages1" />
        </p>
      </div>,
      <div className={'sliderTextWrapper'}>
          <div className={'col-md-4 col-md-offset-1 Active sliderText'}> 
            <h6 style={{fontSize: '32px',fontWeight: '600'}}> We make it easy for you </h6>
            <span style={{fontSize: '22px',color: '#000',fontWeight: '400' , lineHeight: '32px'}}>We bring back your laundry clean, folded and sorted. All you have to do is put it away.</span>  
          </div>
        <p className={'col-md-7'}>
          <img src={img3} onDragStart={handleDragStart} className="sliderImages sliderImages2" />
        </p>
      </div>,
      <div className={'sliderTextWrapper'}>
        <div className={'col-md-5 Active sliderText'}> 
          <h6 style={{fontSize: '32px',fontWeight: '600'}}>Only a $1.89/lb</h6>
          <span style={{fontSize: '22px',color: '#000',fontWeight: '400' , lineHeight: '32px'}}>Bag it all up and we will take care of the rest!</span>  
        </div>
      <p className={'col-md-7'}>
        <img src={img2} onDragStart={handleDragStart} className="sliderImages sliderImages3" />
      </p>
    </div>,
    <div className={'sliderTextWrapper'}>
        <div className={'col-md-5 Active sliderText'}> 
          <h6 style={{fontSize: '32px',fontWeight: '600'}}>Same day service guaranteed </h6>
          <span style={{fontSize: '22px',color: '#000',fontWeight: '400' , lineHeight: '32px'}}>Delivered to your door</span>  
        </div>
    <p className={'col-md-7'}>
      <img src={img4} onDragStart={handleDragStart} className="sliderImages sliderImages4" />
    </p>
    </div>,
];

const Gallery = () => {
    const [slideNum,setslideNum] =  React.useState(0)
    const slideTo =(activeIndex) => {
        setslideNum(activeIndex.slide)
    }
  return (
    <>
     <div class="row section-carousel works">
         <h2 className="col-md-12 heading m-b-10">How it Works ?</h2>
    {/* <div class="col-md-4 sliderTextWrapper desktopView">
        
        <div className={slideNum === 0 ? 'show'+' slideNumber' : 'hide'+' slideNumber'}>01</div>
        <p className={slideNum === 0 ? 'Active sliderText' : 'inActive sliderText'}>          
          We Pick<br/> up your<br/> dirty<br/> clothes</p>
        <div className={slideNum === 1 ? 'show'+' slideNumber' : 'hide'+' slideNumber'}>02</div>
        <p className={slideNum === 1 ? 'Active sliderText' : 'inActive sliderText'}>
          We wash<br/> them <br/> nice and <br/>clean</p>
        <div className={slideNum === 2 ? 'show'+' slideNumber' : 'hide'+' slideNumber'}>03</div>
        <p className={slideNum === 2 ? 'Active sliderText' : 'inActive sliderText'}>
          Press<br/>  and<br/>  fold <br/> them</p>
        <div className={slideNum === 3 ? 'show'+' slideNumber' : 'hide'+' slideNumber'}>04</div>
        <p className={slideNum === 3 ? 'Active sliderText' : 'inActive sliderText'}>
          Deliver<br/>  them <br/> at your <br/> doorsteps</p>
    </div> */}
    <div className="col-md-12 col-sm-12">
        <AliceCarousel 
        autoPlay={true}
        autoPlayInterval={5500}
        animationDuration={2000}
        autoPlayStrategy={'none'}
        animationType="slide"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        mouseTracking items={items}
        onSlideChanged={slideTo}
        responsive={responsive}    
        />
     </div>
     {/* <div class="col-sm-12 sliderTextWrapper mobileView">      
     <Works /> 
    </div> */}
    </div>
    </>
  );
}

export default Gallery;
