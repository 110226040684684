import React from 'react';  
import ReviewMobile from './reviewMobile';
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};


const Review = () => {
return (
        <>
        <div className="container-fluid review-container desktopView">
            <div className="row">
                <div className="col-md-6 col-sm-12">                    
                    <p className="review-title-para">My daughter told me about this...I tried it and it was of great help as a time consuming chore was done for me. Very professional. Loved it.</p>
                    <h3 className="review-title-name">Shonu Bhasin &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                    </h3>
                    <p className="review-from">Google Review</p>
                </div>
                <div className="col-md-6 col-sm-12">                    
                    <p className="review-title-para">Use them for the first time absolutely awesome. These guys should teach customer service. Will use them from now on on a regular basis highly recommend them.</p>
                        <h3 className="review-title-name">Dave Sullivan &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                        </h3>
                    <p className="review-from">Facebook Review</p>
                </div>
            </div>
        </div>
        <div>
          <ReviewMobile />
        </div>
        </>
)}
export default Review;