import 'date-fns';
import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';

const styles = {
  fontFamily: "sans-serif",
  display: "flex",
  justifyContent:"start"
};

function DateTimePickerScheduler(props) {

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  useEffect(() => {
    // Props Update
  },[]);

  const datepickerOpen = () => {
    document.body.style.position  = 'fixed';
  };
  const datepickerClose = () =>{
    document.body.style.position  = 'relative';
  }

  return (
    
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justify="space-around">
        <div className='bookingSlotRadio'>
          <DatePicker
            style={{width: '60% !important',marginRight:'20px'}}
            label="Please Select Date"
            inputVariant="outlined"
            value={props.onSchedulingTime}
            minDate={moment().format('YYYY-MM-DD')}
            format={"YYYY-MM-DD"}
            minutesStep={5}
            variant={"dialog"}
            onChange={props.onChangeTime}
            onOpen={datepickerOpen}
            onClose={datepickerClose}
          />
          <button value={"07:30:00"} onClick={props.handleShiftChange} className={`btn square light btn btn-primary booking firstBooking ${props.bookingSlot === '07:30:00'? 'selected':'notSelected' }`}>8 AM</button>
          <button value={"09:30:00"} onClick={props.handleShiftChange} className={`btn square light btn btn-primary booking ${props.bookingSlot === '09:30:00'? 'selected':'notSelected' }`}>10 AM</button>
          <button value={"11:30:00"} onClick={props.handleShiftChange} className={`btn square light btn btn-primary booking lastBooking ${props.bookingSlot === '11:30:00'? 'selected':'notSelected' }`}>12 PM</button>
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DateTimePickerScheduler;
