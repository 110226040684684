import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Works from './works';
 
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};

const items = [<div className="col-md-6 col-sm-12">                    
<p className="review-title-para">My daughter told me about this...I tried it and it was of great help as a time consuming chore was done for me. Very professional. Loved it.</p>
<h3 className="review-title-name">Shonu Bhasin <br/>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
</h3>
<p className="review-from">Google Review</p>
</div>,
<div className="col-md-6 col-sm-12">                    
<p className="review-title-para">Use them for the first time absolutely awesome. These guys should teach customer service. Will use them from now on on a regular basis highly recommend them.</p>
    <h3 className="review-title-name">Dave Sullivan<br/>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
    </h3>
<p className="review-from">Facebook Review</p>
</div>

];

const ReviewMobile = () => {
    const [slideNum,setslideNum] =  React.useState(0)
    const slideTo =(activeIndex) => {
        setslideNum(activeIndex.slide)
    }
  return (
    <div className="container-fluid review-container mobileView">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="review-section-title">Testimonials
                    </h1>
                </div>
                <br/>
                <div className="col-md-12 col-sm-12">
                    <AliceCarousel 
                    autoPlay={true}
                    autoPlayInterval={12000}
                    animationDuration={400}
                    autoPlayStrategy={'none'}
                    animationType="fadeout"
                    infinite
                    touchTracking={false}
                    disableDotsControls
                    disableButtonsControls
                    mouseTracking items={items}
                    onSlideChanged={slideTo}
                    responsive={responsive}    
                />
            </div>
        </div>
    </div>
  );
}

export default ReviewMobile;