import React, { useState } from 'react';
import '../../assets/sass/scheduler.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DateTimePickerScheduler from './datetimepicker';
import axios from 'axios';
import moment from 'moment';
import GoogleAddress from './GoogleAddress';
import Flippbackground from '../../images/flippBackground.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Scheduler = props => {
  const classes = useStyles();
  const [successContainer, setsuccessContainer] = React.useState(false);
  const [PickupFinal, setPickupFinal] = React.useState(false);
  const [respMsg, setrespMsg] = React.useState('Processing');
  const [showEarnPage, setshowEarnPage] = React.useState(false);
  const [value, onChange] = React.useState(moment().format('yyyy-MM-DDTHH:mm'));
  const [LocationAddr, setLocationAddr] = React.useState('');
  const [SchedulingTime, setSchedulingTime] = React.useState(
    moment({ hour: 8, minute: 0, second: 0 }).add(1, 'days')
  );
  const [placeholderTime, setplaceholderTime] = React.useState(false);
  const [FirstName, setFirstName] = React.useState('');
  const [LastName, setLastName] = React.useState('');
  const [EmailId, setEmailId] = React.useState('');
  const [userNotes, setuserNotes] = React.useState('');
  const [userCoupon, setuserCoupon] = React.useState('');
  const [correctTime, setcorrectTime] = React.useState(true);
  const [phoneUser, setphoneUser] = React.useState('');
  const [errorMsg, seterrorMsg] = React.useState(null);
  const [respIcon, setrespIcon] = React.useState('process');
  const [bookingSlot, setbookingSlot] = useState('07:30:00');

  function schedulePickupNext() {
    let dr = new Date(SchedulingTime);
    if (
      SchedulingTime.length < 10 ||
      correctTime === false ||
      dr.getHours() < 0 ||
      dr.getHours() > 24
    ) {
      seterrorMsg('Please Select Correct Time!');
    }
    if (LocationAddr.description === undefined && LocationAddr.length <= 10) {
      // if(LocationAddr.length <=10){
      seterrorMsg('Please Enter Correct Location !');
      // }
    } else {
      seterrorMsg(null);
      setPickupFinal(true);
    }
  }
  const changeTime = date => {
    if (date.hour() < 0 || date.hour() > 24) {
      setSchedulingTime(date);
      setcorrectTime(false);
      seterrorMsg('Please Select Time from 12:00 AM to 11:50 PM !');
    } else {
      setcorrectTime(true);
      setSchedulingTime(date);
      seterrorMsg(null);
    }
  };
  const handleShiftChange = event => {
    setbookingSlot(event.target.value);
  };
  function schedulePickupFinal() {
    if (
      FirstName.length <= 1 ||
      LastName.length <= 1 ||
      EmailId.length <= 1 ||
      phoneUser.length <= 6
    ) {
      seterrorMsg('Please Fill Mandatory Fields !');
    } else {
      const firstName = FirstName;
      const lastName = LastName;
      const time = SchedulingTime.format(`YYYY-MM-DDT${bookingSlot}`);
      let location = '';
      if (LocationAddr.description === undefined) {
        location = LocationAddr;
      } else {
        location = LocationAddr.description;
      }
      const email = EmailId;
      const phone = phoneUser;
      const notes = userNotes;
      let coupon = userCoupon;
      if (props?.code) {
        coupon = coupon + ' - ' + props.code;
      }
      const url = process.env.GATSBY_API_URL + '/acuity';
      const mainsite = process.env.GATSBY_MAINSITE ? process.env.GATSBY_MAINSITE : 'toronto';

      const data = {
        mainsite: mainsite,
        fname: firstName,
        lname: lastName,
        email: email,
        time: time,
        location: location,
        phone: phone,
        notes: notes,
        coupon: coupon,
      };

      const headers = {
        'Access-Control-Allow-Origin': '*',
      };

      axios.post(url, data, { headers: headers }).then(res => {
      if (res.data.error === undefined) {
          setrespIcon('success');
          setrespMsg('Laundry Pick Up Scheduled');
        } else {
          setrespIcon('error');
          if (res.data.error === 'no_available_calendar') {
            setrespMsg('Oops! Please select a later pickup time. If you need assistance, send us a text at (647) 722-4585.');
          } else {
            setrespMsg(res.data.message);
          }
        }
      });
      setsuccessContainer(true);
      setPickupFinal(true);
    }
  }
  function schedulePickupAgain() {
    setsuccessContainer(false);
    setPickupFinal(false);
    setrespIcon('process');
    setrespMsg(null);
  }
  function Earn() {
    setshowEarnPage(true);
  }
  async function Wash() {
    setshowEarnPage(false);
  }
  return (
    <div className="row-no-gutters">
      <div
        className={
          props?.code
            ? 'form-container scheduler flippCode'
            : 'form-container scheduler'
        }
      >
        <Card className={'card-scheduler'}>
          <Row className="row-no-gutters">
            <div class="form-bg">
              {successContainer === true ? (
                <div
                  className="successMessage"
                  style={{ transform: 'translateY(20%)' }}
                >
                  <div
                    className="successContainer"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div className={'circle-border ' + respIcon}></div>
                    <div className={'circle'}>
                      {respIcon == 'success' ? (
                        <div className="success"></div>
                      ) : (
                        <i
                          className={
                            'fa fa-exclamation-triangle fa-2x ' + respIcon
                          }
                          style={{
                            fontSize: '30px',
                            transform: 'translate(50%, 50%)',
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {respIcon == 'success' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#000',
                        textAlign: 'center',
                        marginBottom: '10px',
                        marginTop: '15px',
                      }}
                    >
                      Please expect the driver to be there between
                      {bookingSlot === '07:30:00' ? ' 7:30 AM - 8:30 AM '
                        : bookingSlot === '09:30:00' ? ' 9:30 AM - 10:30 AM '
                        : ' 11:30 AM - 12:30 PM '}
                    </div>
                  )}
                  {respIcon == 'success' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#000',
                        textAlign: 'center',
                        marginBottom: '10px',
                        marginTop: '10px',
                      }}
                    >
                      The driver will call you before arrival
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#000',
                      textAlign: 'center',
                      marginBottom: '10px',
                      marginTop: '10px',
                  }}
                  >
                    {respMsg}
                  </div>
                  <br />
                  <div
                    className={'bottomAction'}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div style={{ marginRight: '0px' }}>
                      {respMsg !== null && (
                        respIcon === 'error' ? (
                          <Button
                            className="btn square dark"
                            onClick={schedulePickupAgain}
                          >
                            Try Again
                          </Button>
                        ) : (
                          <Button
                            className="btn square dark"
                            onClick={schedulePickupAgain}
                          >
                            Book Again
                          </Button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {PickupFinal === false ? (
                    <>
                      <div
                        class="container-btn"
                        style={{
                          marginBottom: '0px',
                          padding: '5px 0px 0px 15px',
                        }}
                      >
                        <p className={'introText'}>
                          We pick up your dirty laundry and bring it back to you
                          clean, folded and sorted, <b>all on the same day</b>
                        </p>
                      </div>
                      <div class="container-form">
                        {showEarnPage === false ? (
                          <Col className="col-md-12 col-lg-12">
                            <h2
                              className="form-title"
                              style={{ paddingBottom: '15px' }}
                            >
                              Schedule a Pick up Now
                            </h2>
                            <GoogleAddress
                              onLocationAddr={LocationAddr}
                              onsetLocationAddr={setLocationAddr}
                            />
                            {/* <TextField id="standard-basic" label="Enter Your Location" variant="outlined"
                          placeholder={'Address'}
                          value={LocationAddr} 
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{marginBottom: "20px"}}
                            onChange={(event) => {
                              setLocationAddr(event.target.value)
                            }}
                          /> */}
                            <DateTimePickerScheduler
                              onSchedulingTime={SchedulingTime}
                              onplaceholderTime={placeholderTime}
                              onChangeTime={changeTime}
                              bookingSlot={bookingSlot}
                              handleShiftChange={handleShiftChange}
                            />
                            {errorMsg !== null ? (
                              <p className="errorMesg">
                                <i
                                  class=" fa fa-info"
                                  style={{
                                    border: '1px solid red',
                                    borderRadius: '50%',
                                    padding: '2px 6px',
                                  }}
                                ></i>
                                &nbsp;&nbsp;&nbsp;{errorMsg}
                              </p>
                            ) : (
                              ''
                            )}
                            <div className={'bottomAction'}>
                              <div
                                className="sch-btn"
                                style={{ marginRight: '20px' }}
                              >
                                <Button
                                  className="btn square dark"
                                  onClick={schedulePickupNext}
                                >
                                  Next
                                </Button>
                              </div>
                              <div>
                                <a href="tel:+16477224585">
                                  <Button className="btn square light">
                                    Call Us
                                  </Button>
                                </a>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col>
                            <h3>Coming Soon</h3>
                          </Col>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <h2
                        className="form-title"
                        style={{ paddingBottom: '15px' }}
                      >
                        Some more Info
                      </h2>
                      <TextField
                        id="userFirstName"
                        label="First name"
                        variant="outlined"
                        className={'userField firstName'}
                        value={FirstName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setFirstName(event.target.value);
                        }}
                      />
                      <TextField
                        id="userLastName"
                        label="Last name"
                        variant="outlined"
                        className={'userField lastName'}
                        value={LastName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setLastName(event.target.value);
                        }}
                      />
                      <TextField
                        id="userEmail"
                        label="Email Id"
                        variant="outlined"
                        className={'userField userEmail'}
                        value={EmailId}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setEmailId(event.target.value);
                        }}
                      />
                      <TextField
                        id="userPhone"
                        label="Phone No."
                        variant="outlined"
                        className={'userField userPhone'}
                        value={phoneUser}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setphoneUser(event.target.value);
                        }}
                      />
                      <TextField
                        id="userNotes"
                        label="User Notes"
                        variant="outlined"
                        className={'userField userNotes'}
                        value={userNotes}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setuserNotes(event.target.value);
                        }}
                      />
                      <TextField
                        id="userCoupon"
                        label="Coupon Code"
                        variant="outlined"
                        className={'userField userCoupon'}
                        value={userCoupon}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        onChange={event => {
                          setuserCoupon(event.target.value);
                        }}
                      />
                      {errorMsg !== null ? (
                        <p className="errorMesg">
                          <i
                            class=" fa fa-info"
                            style={{
                              border: '1px solid red',
                              borderRadius: '50%',
                              padding: '2px 6px',
                            }}
                          ></i>
                          &nbsp;&nbsp;&nbsp;{errorMsg}
                        </p>
                      ) : (
                        ''
                      )}
                      <div class="bottomAction">
                        <div class="sch-btn" style={{ marginRight: '0px' }}>
                          <Button
                            className="col-md-5 btn square dark"
                            onClick={schedulePickupFinal}
                            style={{ marginRight: '15px' }}
                          >
                            Schedule
                          </Button>
                          <Button
                            className="col-md-5 btn square light"
                            onClick={schedulePickupAgain}
                          >
                            Go Back
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Row>
        </Card>
        {props?.code ? (
          <div className={'flippbackground'}>
            <img src={Flippbackground} alt={'Flipp Meets Laundrytogo'} />
            <br />
            <p className={'flippIntroPara desktopView'}>
              A gesture of appreciation. 40lb/month (approximately 2 full bags)
              free for all Flipp parents and families.
              <br />
              $1.49/lb for any weight over 40 lbs of laundry.
            </p>
            <p className={'flippIntroPara mobileView'}>
              <span style={{ fontSize: '21px' }}>
                A gesture of appreciation.
              </span>
              <br></br>
              <br></br>
              <span style={{ fontSize: '16px' }}>
                40lb/month free for all Flipp parents and families. $1.49/lb for
                any weight over 40 lbs of laundry.
              </span>
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default Scheduler;
