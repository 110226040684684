import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import img1 from "../../images/slider_new_1.svg"   //howitworks3.svg Man With bag
import img2 from "../../images/slider_new_2.svg"   //howitworks4.svg"; //Girl With Machine
import img3 from "../../images/slider_new_3.svg"   //howitworks5.svg"; //Man Folding Clothes
import img4 from "../../images/slider_new_4.svg"   //howitworks6.svg"; //Girl Delivering Clothes
import 'react-alice-carousel/lib/alice-carousel.css';
// Old
/*import img1 from "../../images/slider1.svg"; //old lady
import img2 from "../../images/slider2.svg"; //Garbage Bag
import img4 from "../../images/slider3.svg"; //Girls Dancing
import img3 from "../../images/slider4.svg"; //Time
';
*/ 
 
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};

const items = [
  <img src={img1} onDragStart={handleDragStart} className="sliderImages sliderImages1" />,
  <img src={img2} onDragStart={handleDragStart} className="sliderImages sliderImages2" />,
  <img src={img3} onDragStart={handleDragStart} className="sliderImages sliderImages3" />,
  <img src={img4} onDragStart={handleDragStart} className="sliderImages sliderImages4" />,
];

const Works = () => {
    const [slideNum,setslideNum] =  React.useState(0)
    const slideTo =(activeIndex) => {
        setslideNum(activeIndex.slide)
    }
  return (
    <>
     <div className="container-fluid section-works" style={{padding: "10px 40px 10px"}}>
         <h2 className="col-md-12 heading m-b-10">How it Works ?</h2>        
            <div className="row" style={{marginBottom:"80px"}} className={'work-row row'}>
                
                <div class="col-md-12 sliderTextWrapper mobileView">
                        <h4 className={'Active mobileHeadingWorks'}>          
                            No more laundry day!
                        </h4>
                        <p className={'Active mobileParaWorks'}>          
                            Sit back and relax, Let us take care of this chore.
                        </p>
                </div>
                <div className="col-md-12">
                    <img src={img3} style={{maxHeight: "560px", width: "100%"}} />
                </div>
                </div>
                <div className="row" style={{marginBottom:"80px"}} className={'work-row row'}>
                    
                    <div class="col-md-12 sliderTextWrapper mobileView">
                            <h4 className={'Active mobileHeadingWorks'}>          
                                We make it easy for you
                            </h4>
                            <p className={'Active mobileParaWorks'}>          
                            We bring back your laundry clean, folded and sorted. 
                            All you have to do is put it away.
                            </p>
                    </div>
                    <div className="col-md-12">
                        <img src={img1} style={{maxHeight: "560px", width: "100%"}} />
                    </div>
                </div>
                <div className="row" style={{marginBottom:"80px"}} className={'work-row row'}>
                    <div class="col-md-12 sliderTextWrapper mobileView">
                            <h4 className={'Active mobileHeadingWorks'}>          
                                Budget friendly 
                            </h4>
                            <p className={'Active mobileParaWorks'}>          
                                Fill up the garbage bags! Its only $1.89/lb.
                            </p>
                    </div>
                    <div className="col-md-12">
                        <img src={img2} style={{maxHeight: "560px", width: "100%"}} />
                    </div>
                </div>
                <div className="row" style={{marginBottom:"80px"}} className={'work-row row'}>
                    <div class="col-md-12 sliderTextWrapper mobileView">
                            <h4 className={'Active mobileHeadingWorks'}>          
                                No worry of delays
                            </h4>
                            <p className={'Active mobileParaWorks'}>          
                                Guaranteed same day service.
                            </p>
                    </div>
                    <div className="col-md-12">
                        <img src={img4} style={{maxHeight: "560px", width: "100%"}} />
                    </div>
            </div>
            </div>                                        
    </>
  );
}

export default Works;
